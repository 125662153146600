import { template as template_486c753cfe19484990d16622bf6bae0b } from "@ember/template-compiler";
const SidebarSectionMessage = template_486c753cfe19484990d16622bf6bae0b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
