import { template as template_fe0066bf2ccd43aa96b27d610ac11e73 } from "@ember/template-compiler";
const FKLabel = template_fe0066bf2ccd43aa96b27d610ac11e73(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
