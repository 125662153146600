import { template as template_6753d917719c40e794d63c71ce825250 } from "@ember/template-compiler";
const FKText = template_6753d917719c40e794d63c71ce825250(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
