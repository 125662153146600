import { template as template_01a5e784f6ac4fb99da5e0d8b77210cf } from "@ember/template-compiler";
const WelcomeHeader = template_01a5e784f6ac4fb99da5e0d8b77210cf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
